import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import serviceList, { BuildReactIcon } from "../scripts/serviceList";

const ServiceAreaOne = () => {
  return (
    <>
      <div className="service-area bg-relative pd-top-100">
        <img
          className="position-bottom-left top_image_bounce"
          src="assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">CREATIVE SERVICES</h6>
            <h2 className="title">
              {" "}
              Your Partner In <span>Digital</span> Success{" "}
            </h2>
            <p className="pt-3">
              {" "}
              1Technologies is an Interactive Web development and Internet
              marketing company based in Delhi, India. We are proficient in
              creating a strong web presence for your business in the best
              possible manner. Since our inception, we are focused on working
              ‘with’ our clients rather than working ‘for’ clients. We believe
              as a partner we share an equal responsibility to make the project
              a big success. Whenever we are working with a client, we try to
              win our client with the quality, commitment and responsiveness. We
              would be glad to be a part of your success as well and form a long
              term business association with you.{" "}
            </p>
          </div>
          <div className="row">
            {serviceList.slice(0, 6).map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-service-inner text-center">
                  <div className="thumb">
                    {/*<img src={data.img} alt={data.alt} />*/}
                    {BuildReactIcon(data.icon)}
                  </div>
                  <div className="details">
                  <Link to={data.Link}> <h5>{data.title} </h5> </Link>  
                    <h5>
                      <Link to={data.Link}> </Link>
                    </h5>
                   
                    <p>{data.des}</p>
                    <Link className="btn btn-border-base" to={data.Link}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceAreaOne;
