import React from "react";
import Marquee from "react-fast-marquee";
const BrandAreaOne = () => {
  return (
    <div className='about-area bg-gray pt-5 pb-5'>
      <div className='container'>
        <div className='client-slider'>
          <Marquee gradient={false}>
            <div className='thumb'>
              <img src='assets/img/client/indianprivatedriver-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/kirtishinteriors-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/lelopolicy-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/snsssngo-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/afreegroup-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/simplitax-logo.png' alt='img' />
            </div>
            <div className='thumb'>
            <img src='assets/img/client/decentvillas-logo.png' alt='img' />
          </div>
          <div className='thumb'>
          <img src='assets/img/client/nexuslawfirms-logo.png' alt='img' />
        </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BrandAreaOne;
