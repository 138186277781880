import React, { useEffect } from 'react'
import NavbarTwo from '../components/NavbarTwo';
import FooterOne from '../components/FooterOne';
import Breadcrumb from '../components/Breadcrumb';
import ContactAreaOne from '../components/ContactAreaOne';
import {FaCheckCircle,} from "react-icons/fa";
import WorkProcessOne from '../components/WorkProcessOne';

const WebsiteDesign = () => {
  useEffect(() => {
    document.title="Website Designing Company in Delhi | Website Designing Services in Delhi" 
  }, [])
  
  return (
    <>   
 <NavbarTwo />
<Breadcrumb title={"Website Design"} />
  <div className='service-area pd-top-120 '>
        <div className='container'>
          <div className='row'>
      <div className='col-lg-12 col-xl-12 col-12'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  {/* <div className='thumb'>
                    <img src='../../assets/img/service/7.png' alt='img' />
                  </div> */}
                  <div className='details'>
          <h4> Website Designing Company in Delhi NCR </h4>
          <p> 1Technologies is incredibly delighted to build a website to serve business or personal needs because the company firmly believes that “What separates design from art is that it need to be functional.” Our website designers make sure they have a strong international presence. The necessary connections are included in the design portfolio so that the visitor can receive a general impression of an intelligent website design. Web design is a task that leaves a lasting impression and forges a powerful corporate brand. Our web designers create a highly trustworthy company with a very professional appearance. </p>
          <p> The overwhelming popularity of mobile devices like tablets, ipads, and iphones nowadays has forced website and app developers to design responsive websites. </p>
          <p> Now, clients do have the option to browse and seek information irrespective of the device, they are having. Responsive Web designing enables the web page to look good on all devices. Responsive websites can be hidden, shrink, enlarge or content can be moved so that it looks good on the screen.A website is a key to every successful business. A high-performance website is useful for higher audience engagement and conversion rates. Your website is a powerful tool for expanding your business worldwide and will make a positive and long-lasting impact on customers, audience. Every successful business starts with a great website and we deliver you the same. Being the top-notch Website Designing Company In Delhi. Designing a great website requires skills and creative insights and we have it both that turns your visual image into virtual reality. </p>
                    <h4>Get touch have any question ?</h4>
                    <p>
                      It is a long established fact that a reader will be distr
                      acted bioiiy the end gail readable content of a page when
                      looking.
                    </p>
                    <div
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            What services do you offer?
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            Many desktop publishing packages and web page
                            editors now use Lorem Ipsum as their default model
                            text, search for 'lorem ipsum' will uncover
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            How long does it take for you to complete a project?
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            Many desktop publishing packages and web page
                            editors now use Lorem Ipsum as their default model
                            text, search for 'lorem ipsum' will uncover
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            How much does it cost to work with your agency?
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            Many desktop publishing packages and web page
                            editors now use Lorem Ipsum as their default model
                            text, search for 'lorem ipsum' will uncover
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4> We Work with all Advance Feature to make it Attractive </h4>
<p> At 1Technologies we design responsive websites which changes its response according to the screen resolution. Responsive websites have same look and appearance of all objects designed in websites on all kind of devices. </p>
<p> 1Technologies has spreads its wings in different domain by providing its Website Designing Services to individuals/bussinesses and by optimizing these websites in best possible way with an aim to establish them in india.If you have a small bussiness and looking for an online presence or already have a website but want to earn some serious bussiness out of it,then 1Technologies is the Best Website Designing Company in Delhi India to make you king of your industry.   </p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                <li>  <FaCheckCircle /> Responsive website design . </li>
              <li> <FaCheckCircle /> Accessible to all users. </li>
             
               <li> <FaCheckCircle />  Fast Load Times. </li>
               <li> <FaCheckCircle />  Effective Navigation. </li>
               <li> <FaCheckCircle />  Browser Consistency. </li>
                        </ul>
                      </div>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                        <li> <FaCheckCircle />  Well Planned Information Architecture. </li>
               <li> <FaCheckCircle /> Social media integration.  </li>
              <li>  <FaCheckCircle />  Usable Contact Forms. </li>
              <li>  <FaCheckCircle /> Seo Friendly  </li>
              <li>  <FaCheckCircle /> Payment Gateway Integration </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<WorkProcessOne />

      
      <ContactAreaOne  />


<FooterOne />
    </>
  )
}

export default WebsiteDesign