import React, { useEffect } from 'react'
import BannerOne from "../components/BannerOne";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/FooterOne";
import ServiceAreaOne from "../components/ServiceAreaOne";
import NavbarTwo from "../components/NavbarTwo";
import BrandAreaOne from "../components/BrandAreaOne";

const HomeOne = () => {
  useEffect(() => {
    document.title="Website Designing Company in Delhi | Website Designing Services in Delhi" 
  }, [])
  return (
    <>
      <NavbarTwo />
      <BannerOne />
      <ServiceAreaOne />
      <FaqAreaOne />
      <CounterAreaOne />
      <ContactAreaOne />
      <BrandAreaOne />
      <FooterOne />
    </>
  );
};

export default HomeOne;
