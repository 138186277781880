import React from "react";
import { FaArrowRight, FaEnvelope, FaMapMarker, FaMapMarkerAlt, FaPhoneAlt, FaPlus } from "react-icons/fa";

const ContactAreaFour = () => {
  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className='contact-area pd-top-90 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
          <div
              className='col-lg-4'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
              
                <h2 className='title mb-4'>
                Need <span>  Help </span>
                </h2>

                <div className='media mb-3'>
                  <div className='media-left me-3'>
                  <FaMapMarkerAlt className="text-primary" />
                  </div>
                  <div className='media-body'>
                    <h5 > House no-182, Bhowapur Near Durga Mandir, Kaushambi, Ghaziabad, Uttar Pradesh 201010</h5>
                  </div>
                </div>


                <div className='media mb-3'>
                  <div className='media-left me-3'>
                  <FaPhoneAlt   className="text-primary" />
                  </div>
                  <div className='media-body'>
                    <h5 > +91 8287234369 </h5>
                  </div>
                </div>
                <div className='media mb-3'>
                  <div className='media-left me-3'>
                 <FaEnvelope  className="text-primary" />
                  </div>
                  <div className='media-body'> 
                    <h5 > info@1technologies.in </h5>
                   
                  </div>
                </div>
            
              </div>
            </div>


            <div
              className='col-lg-8 '
              data-aos='fade-right'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-5 mt-lg-0'>
                <h6 className='sub-title'>GET IN TOUCH</h6>
                <h2 className='title'>
                GET IN <span> TOUCH </span>  WITH US
                </h2>
<p className='content'> Interested In Working Together? Fill Up Your Details And We Will Get Back To You Very Shortly.
 </p>
                <form className='mt-4'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='single-input-inner style-border'>
                        <input type='text' placeholder='Name' />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='single-input-inner style-border'>
                        <input type='text' placeholder='Phone' />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='single-input-inner style-border'>
                        <input type='text' placeholder='Email' />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='single-input-inner style-border'>
                        <textarea placeholder='Message' defaultValue={""} />
                      </div>
                    </div>
                    <div className='col-12'>
                      <a className='btn btn-border-gray mt-0' href='#'>
                        Submit <FaArrowRight />
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </>
  );
};

export default ContactAreaFour;
