import React, { useEffect } from 'react'
import NavbarTwo from '../components/NavbarTwo';
import FooterOne from '../components/FooterOne';
import Breadcrumb from '../components/Breadcrumb';
import ContactAreaOne from '../components/ContactAreaOne';

const WebsiteMaintainance = () => {
  useEffect(() => {
    document.title="Website Maintainance Services in Delhi" 
  }, [])

  return (
    <> 
     
     <NavbarTwo />
<Breadcrumb title={"Website Maintainance"} />
  <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
      <div className='col-lg-12 col-xl-12 col-12'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  {/* <div className='thumb'>
                    <img src='../../assets/img/service/7.png' alt='img' />
                  </div> */}
                  <div className='details'>
          <h4> Website Maintainance Services in Delhi NCR </h4>
<p> 1Technologies is a effective web designer in delhi india.We provide  a fresh look to your website.
</p>
          <p> Here we provide you extensive website support and maintenance and offer real time resolution. We can develop website patches and upgrades for.
             We handle existing websites too and provide you continuous, efficient and cost-effective solutions in an appropriate and professional manner. </p>
          <p> This maintenance range from simple website up-dates such as modifying written text or pictures to more extensive website servicing such as adding new WebPages or giving a new look and functionality to your site. </p>
          <p> 1Technologies offers an excellent  Monthly website maintenance packages are affordable and cost effective, we are one amoung the website maintenance companies in Chennai, India. our monthly / annual website maintenance plans includes all website maintenance services like website updates, image or text modifications, new dynamic features,seo service, website backup, website redesign, mobile compatibility,  browser compatibility,website backup and malware removal service. website maintenance package is affordable for small and medium enterprises. 
            By annual website maintenance package, we take care of the entire gamut of operations right from keeping your website in google first page results for your important keywords. </p>
                 
                  
                    <h4> We Work with all Advance Feature to make it Attractive </h4>
<p> Our website maintenance programs ensure that your site is always current and your project stays within budget. Our standard program includes, content updates, update images, and support via telephone and e-mail. We include all of the services you require in order to ensure both the short and long-term success of your online marketing. It also includes revising, editing, or otherwise changing existing web pages to keep your website up to date. </p>
<p> Websites are not intended to be static. The value of a practice website is the ability to maintain current information online at a reasonable cost. We assume that you will want to revise some or all of your web pages over time, as well as adding additional web pages according to your business needs. To assist you in maintaining current information online, we offer several options for website maintenance. </p>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  <ContactAreaOne />   
<FooterOne />
    </>
  )
}

export default WebsiteMaintainance