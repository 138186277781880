import { FaPencilRuler } from "react-icons/fa"
import { FaCode } from "react-icons/fa";

export const BuildReactIcon=(value)=>{
  switch(value){
    case "pencil":
      return <FaPencilRuler/>;
      case "code":
        return <FaCode />;
    default :
      return "";  

  }
}

let serviceList = [
  {
    title: "Website Design",
    des: "We are a fresh, innovative, creative and experienced team creating to all your design requirements. Our design services will cover all of your creative needs including graphic design, web design, logo.",
    img: "assets/img/service-icon/1.png",
    alt: "Website Design",
    Link:"/our-services/web-design/",
    icon:"pencil"

  },
  {
    title: "Website Development",
    des: "1Technologies specialize in web development of all kind. Right from setting up a simple corporate website to developing a customized high scale website, we do it all.",
    img: "assets/img/service-icon/2.png",
    alt: "Website Development",
    Link:"/our-services/web-development/",
    icon:"code"
  },
  {
    title: "E-commerce Development",
    des: "1Technologies offers custom ecommerce development services to help you run ecommerce store with ease. We are a top rated ecommerce developer in India for open source solutions.",
    img: "assets/img/service-icon/3.png",
    alt: "E-commerce Development",
    Link:"/our-services/ecommerce-development/",
  },
  {
    title: "Digital Marketing",
    des: "Expert Digital Marketing Services with successful conversion metrics. We are leading digital marketing strategy expert known for social media, search engine optimization, search engine marketing etc.",
    img: "assets/img/service-icon/1.png",
    alt: "Digital Marketing",
    Link:"/our-services/digital-marketing/",
  },
  {
    title: "Wordpress Development",
    des: "1Technologies provides quality wordpress development services to clients across the world. Based out of New Delhi, India, we have an expert team of dedicated wordpress web development professionals.",
    img: "assets/img/service-icon/2.png",
    alt: "Wordpress Development",
    Link:"/our-services/wordpress-development/",
  },

  {
    title: "Website Maintainance",
    des: "Our website maintenance programs ensure that your site is always current and your project stays within budget. Our standard program includes, content updates, update images, and support via telephone and e-mail.",
    img: "assets/img/service-icon/3.png",
    alt: "Website Maintainance",
    Link:"/our-services/website-maintainance/",
  },
];

export default serviceList;
