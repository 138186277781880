import React, { useEffect } from 'react'
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavbarTwo from "../components/NavbarTwo";
import ContactAreaFour from "../components/ContactAreaFour";

const Contact = () => {
  useEffect(() => {
    document.title="Website Designing Company in Delhi | Contact us" 
  }, [])
  return (
    <>
  
  <NavbarTwo />
      <Breadcrumb title={"Contact us"} />
      <ContactAreaFour />
      <FooterOne />
    </>
  );
};

export default Contact;
