import React from "react";
import ContactMain from "./ContactMain";

const BannerOne = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/banner/0.png")' }}
      >
        <img
          className='animate-img-1 top_image_bounce'
          src='assets/img/banner/2.png'
          alt='img'
        />
        <img
          className='animate-img-2 left_image_bounce'
          src='assets/img/banner/5.svg'
          alt='img'
        />
        <div className='container'>
          <div className='row'>
            <div className='col-xl-8 col-lg-8 col-12 align-self-center'>
              <div className='banner-inner pe-xl-5'>
<h6  className='subtitle' data-aos='fade-right'  data-aos-delay='100' data-aos-duration='1500'>
  Let’s Take Your Business Online </h6>
<h2 className='title' data-aos='fade-right' data-aos-delay='200' data-aos-duration='1500' >
We took best steps to increase the <span> Digital Presence </span> of your business. </h2>
<p className='content pe-xl-5' data-aos='fade-right'  data-aos-delay='250' data-aos-duration='1500'>
Looking for a Website Designing company? We are the top rated Website Designing company in Delhi with 4+ years of experience, offering the top-notch and high Quality Website Design & Development Services . Hire Website Designing Company in Delhi to get the user-friendly, fully-featured and highly interactive website for your business. </p>
        </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-4'>
           
             
            </div>
          </div>
        </div>
      </div>
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default BannerOne;
