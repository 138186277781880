import React from "react";
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarker,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>

    <div className="whatsapp">
    <Link to='https://api.whatsapp.com/send?phone=918287234369&text=I am interested in your service' target='_blank'><img src='https://1technologies.in/assets/img/whatsapp.png' alt='whatsapp chat' /></Link>
    </div>
    
      {/* ================== Footer One Start ==================*/}
<footer className='footer-area bg-black bg-cover pt-5'>
     <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_about'>
              <h4 className='widget-title'>About us</h4>
                <div className='details'>
    <p> 1Technologies is an Interactive Web development and Internet marketing company based out of Delhi, India. We are proficient in creating a strong web presence for your business in the best possible manner.
                  </p>
                 </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>
                  <li>
                    <Link to='/our-services/web-design/'>
                      <FaArrowRight /> Website Design
                    </Link>
                  </li>
                  <li>
                    <Link to='/our-services/web-development/'>
                      <FaArrowRight /> Website Development
                    </Link>
                  </li>
          <li> <Link to='/our-services/wordpress-development/'>
                      <FaArrowRight /> Wordpress Development
                    </Link>
                  </li>
                  <li>
                    <Link to='/our-services/digital-marketing/'>
                      <FaArrowRight /> Digital marketing{" "}
                    </Link>
                  </li>

                  <li>
                    <Link to='/our-services/ecommerce-development/'>
                      <FaArrowRight /> Ecommerce Development
                    </Link>
                  </li>
                  <li>
                    <Link to='/our-services/website-maintainance/'>
                      <FaArrowRight /> Website Maintainance
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_about'>
              <h4 className='widget-title'>Contact us</h4>
                <div className='details'>
                 <p className='mt-3'>
                    <FaPhoneAlt /> +91 82872 34369
                  </p>
                  <p className='mt-2'>
                    <FaEnvelope /> info@1technologies.in 
                  </p>
                  <p className='mt-2'>
                    <FaMapMarker /> House no-182, Bhowapur Near Durga Mandir, Kaushambi, Ghaziabad, Uttar Pradesh 201010 
                  </p>
                  <ul className='social-media'>
                    <li>
                    <Link to='https://www.facebook.com/1technologies' target="_blank">
                       <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                    <Link to='https://twitter.com/1_technologies' target="_blank">
                        <FaTwitter />
                        </Link>
                    </li>
                    <li>
                    <Link to='https://www.instagram.com/1technologies.in/' target="_blank">
                        <FaInstagram />
                        </Link>
                    </li>
                    <li>
                    <Link to='https://in.linkedin.com/company/1technologies' target="_blank">
                        <FaLinkedinIn />
                        </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p> ©   <Link to='/' target='_blank'>  1technologies </Link> 2023 | All Rights Reserved </p>
              </div>
              <div className='col-md-6 text-lg-end'>
            <Link to='/contact-us'> Terms &amp; Condition </Link>
                <Link to='/contact-us'> Privacy Policy </Link>
                <Link to='/contact-us'>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
