import React, { useEffect } from 'react'
import AboutAreaOne from "../components/AboutAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/FooterOne";
import WorkProcessOne from "../components/WorkProcessOne";
import NavbarTwo from "../components/NavbarTwo";

const About = () => {
  useEffect(() => {
    document.title="Website Designing Company in Delhi | About us" 
  }, [])
  return (
    <>
      <NavbarTwo />
      <Breadcrumb title={"About Us"} />
      <AboutAreaOne />
      <FaqAreaOne />
      <CounterAreaOne />
      <ContactAreaOne />
      <WorkProcessOne />
      <FooterOne />
    </>
  );
};

export default About;
