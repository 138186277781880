import React from "react";
import { Link } from "react-router-dom";

const ContactAreaOne = () => {
  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <div className='contact-area my-5'>
        <div className='container'>
          <div className='contact-inner-1'>
            <img
              className='top_image_bounce animate-img-1'
              src='../../assets/img/banner/2.png'
              alt='img'
            />
            <img
              className='top_image_bounce animate-img-2'
              src='../../assets/img/about/6.png'
              alt='img'
            />
            <div className='row'>
              <div
                className='col-lg-8'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img className='w-100' src='../../assets/img/bg/4.png' alt='img' />
              </div>
              <div
                className='col-lg-4 wow animated fadeInRight'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>GET IN TOUCH</h6>
                  <h2 className='title'>
                    Bringing Your <span>Vision</span> To Life
                  </h2>
  <p className='content'> We can assist you in Website Design & Development Services, E-commerce solutions, CMS Websites, Custom Application development & Internet Marketing </p>
                  <form className='mt-4'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' name="name" placeholder='Your Name' required />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                      <div className='single-input-inner style-border'>
                        <input type='text' name="phone" placeholder='Your Phone' required />
                      </div>
                    </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' name="email" placeholder='Your Email' />
                        </div>
                      </div>
                     
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' name="subject" placeholder='Your Subject' />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border'>
                          <textarea name="message" placeholder='Message' defaultValue={""} />
                        </div>
                      </div>
                      <div className='col-12'>
<Link className='btn btn-black mt-0 w-100 border-radius-5' type='submit' > Submit now  </Link>
                      </div>
                    </div>
                  </form>
                </div>
             </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default ContactAreaOne;
