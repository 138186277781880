import React from 'react'
import NavbarTwo from '../components/NavbarTwo';
import FooterOne from '../components/FooterOne';
import Breadcrumb from '../components/Breadcrumb';
import {FaCheckCircle,} from "react-icons/fa";
import ContactAreaOne from '../components/ContactAreaOne';
import WorkProcessOne from '../components/WorkProcessOne';

const DigitalMarketing = () => {
  return (
  <>
 
 <NavbarTwo />
<Breadcrumb title={" Digital Marketing"} />
  <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
      <div className='col-lg-12 col-xl-12 col-12'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  {/* <div className='thumb'>
                    <img src='../../assets/img/service/7.png' alt='img' />
                  </div> */}
                  <div className='details'>
          <h4> Internet Marketing Company in Delhi NCR </h4>
<p><b> Did you know that over 95% of local customers go online to search for you, and that 90% of users discover you through organic search, typically via Google?You are losing a lot of business if your company does not appear at the top of search results for your desired keywords.</b>
</p>
  <p> Nowadays, when you consider how to spread the word about your company, the first thing that comes to mind is whether you should use an online platform or a digital marketing solution. Marketing is one of the most important activities for any business because it helps you show off your products to the right people and get them to use your services. </p>
          <p> Marketing, more commonly referred to as digital marketing, has taken on a new meaning in this technology-driven world. Despite the widespread use of digital marketing strategies, many individuals are still unfamiliar with its meaning and operation. As a result, they are unable to successfully implement these strategies. First, let’s talk about what digital marketing means. </p>
                    <h4>Get touch have any question ?</h4>
                    <p>
                      It is a long established fact that a reader will be distr
                      acted bioiiy the end gail readable content of a page when
                      looking.
                    </p>
                 
                    <h4> Best Digital Marketing Services in Delhi </h4>
<p> At 1Technologies, we realize that SEO success depends on long-lasting and pertinent ranking. Therefore, each campaign is  customized to give your business unparalleled visibility in search results. A specialist is also dedicated to your account to give you higher rankings, drive more traffic to your website, and answer all your queries.  </p>
<p> We are professional marketing agency. promote business, website over internet there million user visits dialy.</p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                <li>  <FaCheckCircle /> Search Engine Optimization </li>
              <li> <FaCheckCircle /> Link Building Services </li>
             
               <li> <FaCheckCircle />  Social Media Optimization </li>
               <li> <FaCheckCircle /> Social Media Management </li>
               <li> <FaCheckCircle />  Email Marketing Services </li>
               <li> <FaCheckCircle />  Content Writing </li>
                        </ul>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<WorkProcessOne />
  <ContactAreaOne />   
<FooterOne />

  </>
  )
}

export default DigitalMarketing