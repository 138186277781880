import React from "react";

const FaqAreaOne = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className='faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row pd-top-120'>
            <div
              className='col-xl-5 col-lg-6 col-md-8 order-lg-last'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner pt-lg-3'>
                <img
                  className='main-img'
                  src='assets/img/about/4.png'
                  alt='img'
                />
                <img
                  className='animate-img-bottom-right top_image_bounce'
                  src='assets/img/about/5.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-xl-7 col-lg-6'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h6 className='sub-title'>SOME FAQ'S</h6>
<h2 className='title'> Frequently Asked <span>  Questions </span> </h2> </div>
<div className='accordion accordion-inner style-2 accordion-icon-left mt-3' id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
  <button  className='accordion-button' type='button' data-bs-toggle='collapse' data-bs-target='#collapseOne'
aria-expanded='true' aria-controls='collapseOne' > What is the cost of developing a website?
                    </button>
                  </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'  >
                    <div className='accordion-body'>
                    Website development costs can be difficult to estimate. 
                    The number and complexity of features determine website cost. 
                    Also, how quickly do you want your site completed? 1Technologies website development involves several steps. 
                    The three categories are concept design, technology, and content.
                    Our resources will take longer to complete your project if it is complex or full of features.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                  What are the benefits of web design for your business?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Checking your website gives customers their first impression of your company. 
                    You have to leave a positive impression on your audience within these few seconds. 
                    The most efficient way to accomplish this is to ensure your website is engaging, user-friendly, highly responsive, and fast loading. 
                    Your website will attract customers if it has an attractive design that is easily accessible to what they want. 
                    They will decide whether to visit your site again or not based on this. Websites are accessible from anywhere at any time. 
                    You can offer customers access to your website and information about your services outside of business hours. 
                    The importance of this element in website business cannot be overstated. 
                    The website continually protects customers who sign up to receive service in their homes.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
                      data-bs-target='#collapseThree' aria-expanded='false'
                      aria-controls='collapseThree'
                    > What services do you offer along with website design?
                    </button>
                  </h2>
<div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
  data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                    We proffer a huge range of services and solutions to our customers across the globe. 
                    Apart from website design and development, we provide website maintenance, domain registration, web hosting, web app development, mobile app development, e-commerce website development, digital marketing, SEO, branding, graphic designing and so on. We have you covered.
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
                      data-bs-target='#collapseFour' aria-expanded='false'
                      aria-controls='collapseFour'
                    > How long does it take for my web project to be completed?
                    </button>
                  </h2>
<div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour'
  data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
We complete web design, development and software related projects promptly as per the timelines decided at the time of discussion and project approval.
However, any additional features and functionalities required will cause a delay in the deliverables which will be mutually agreed upon and the additional timelines will be clearly discussed. 
We do not compromise on the quality of the project delivery.
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
                      data-bs-target='#collapseFive' aria-expanded='false'
                      aria-controls='collapseFour'
                    > How do I track my project?
                    </button>
                  </h2>
<div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive'
  data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
We have a well-defined project management system wherein we have a team of personnel who are always there to coordinate and manage the project from the start to the end. 
They will be in contact with the resource person assigned for the project to deliver the requisite as per the project needs. 
Time-to-time follow-ups, and regular trial runs are carried out so that the client can review, verify, and come back to us with the changes required before launching the finished project.
                    </div>
                  </div>
                </div>





              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaOne;
